<template>
  <ion-page>
    <div class="background--living" />
    <ion-header
      class="ion-no-border"
    >
      <ion-toolbar
        color="transparent"
      >
        <ion-item
          slot="start"
          lines="none"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="$router.push('/main-view')"
        >
          <img src="../assets/images/arrow-go-back-line.svg">
        </ion-item>
        <ion-title class="text--white text-18">
          Gateway
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div v-if="selectedView === '1'">
        <div class="text--white">
          {{ $t('Gateway seems to be disconnected') }}<br>
          <ul>
            <li>{{ $t('Is your internet connection working?') }}</li>
            <li>{{ $t('Is the gateway connect to the power?') }}</li>
          </ul>
          {{ $t('Please check gateways manual for more information') }}
        </div>
        <div class="mt-30">
          <ion-button
            v-if="userRole === 'Home Owner'"
            color="danger"
            @click="selectedView = '2'"
          >
            {{ $t('Remove my gateway') }}
          </ion-button>
          <ion-button
            class="mt-8"
            @click="logout"
          >
            <ion-spinner
              v-if="loading"
              name="crescent"
            />
            <span v-else>{{ $t('logout') }}</span>
          </ion-button>
        </div>
      </div>
      <div v-else-if="selectedView === '2'">
        <div class="text--white text-18 text--bold">
          Reseting your gateway
        </div>
        <div class="mt-30 text--white">
          Please make sure you understand folowing: <br>
          <ion-item
            color="transparent"
            lines="none"
            class="text--white mt-10 text-16"
          >
            <i class="mdi mdi-alert text--primary text-24 pr-5" />This action will delete all your scenes <br>
          </ion-item>
          <ion-item
            color="transparent"
            lines="none"
            class="text--white text-16"
          >
            <i class="mdi mdi-alert text--primary text-24 pr-5" />This action will delete all your rooms <br>
          </ion-item>
          <ion-item
            color="transparent"
            lines="none"
            class="text--white text-16"
          >
            <i class="mdi mdi-alert text--primary text-24 pr-5" />This action will delete all your devices <br>
          </ion-item>
          <ion-item
            color="transparent"
            lines="none"
            class="text--white text-16"
          >
            <i class="mdi mdi-alert text--primary text-24 pr-5" />You will need to restart the gateway<br>
          </ion-item>
          <div class="mt-30">
            <span class="text--white">Once process completed you will be logged out and need to log back in</span>
          </div>
          <div class="mt-30">
            <ion-item
              color="transparent"
              lines="none"
              class="text--primary"
            >
              <ion-checkbox v-model="understand" /> Understand and wish to continue
            </ion-item>
            <ion-item
              color="transparent"
              lines="none"
              class="text--primary"
            >
              <ion-checkbox v-model="gatewayReset" /> I have restarted my gateway
            </ion-item>
          </div>
          <div class="mt-30">
            <ion-button
              :disabled="!understand || !gatewayReset || loading"
              color="danger"
              @click="restartGateway"
            >
              <ion-spinner
                v-if="loading"
                name="crescent"
              />
              <span v-else>Restart my gateway</span>
            </ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {} from 'ionicons/icons'

import { PushNotifications } from '@capacitor/push-notifications'

export default {
  name: 'Feedback',
  data () {
    return {
      selectedView: '1',
      feedback: '',
      gatewayReset: false,
      understand: false,
      loading: false
    }
  },
  computed: {
    userRole () {
      return this.$store.state.userRole
    }
  },
  methods: {
    restartGateway () {
      this.loading = true
      this.$store.dispatch('restartGateway').then(() => {
        this.$store.dispatch('LogOut').then(() => {
          PushNotifications.removeAllListeners()
          this.$router.push('/login')
        })
      })
    },
    logout () {
      PushNotifications.removeAllListeners()
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push('/login')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/theme/_backgrounds";
.feedback-form {
  @include bg-box-room-device;
}
.form-inner {
    padding-left: 10px;
    padding-right: 10px;
}
</style>